import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import EraEVLevelEditor from "./EraEVLevelEditor";
import IntraEpochVariableRules from "./IntraEpochVariableRules";
import CrossEpochVariableRules from "../CrossEpochVariableRules";

function EraDesigner({ epochVariables, ...props }) {
  
  const [crossEpochVariableRules, setCrossEpochVariableRules] = useState([]);
  const [enableCrossVariableRules, setEnableCrossVariableRules] = useState(
    crossEpochVariableRules.length > 0
  );
  const [intraEpochRules, setIntraEpochRules] = useState([]);
  const [enableIntraEpochVariableRules, setEnableIntraEpochVariableRules] =
    useState(intraEpochRules.length > 0);  

  return (
    <>
      <Row className="mt-4">
        <h4>Era Designer</h4>
        <div className="ml-5">
          Create your own era by hand-picking epochs (and, optionally,
          specifying durations).
        </div>
      </Row>
      {/* <Row><Container>
      <Row className="mt-4">
        <h5 className="eraCreationRowHeader">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) =>
              setEnableIntraEpochVariableRules(ev.target.checked)
            }
          />{" "}
          Epoch Variable Transition Rules
        </h5>
      </Row>{" "}
      <Row>
        {enableIntraEpochVariableRules && (
          <IntraEpochVariableRules
            epochVariables={epochVariables}
            intraEpochRules={intraEpochRules}
            setIntraEpochRules={setIntraEpochRules}
          />
        )}
      </Row>{" "}
      <Row>
        <Col>&nbsp;</Col>
      </Row>
      <Row>
        <h5 className="eraCreationRowHeader">
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={(ev) => setEnableCrossVariableRules(ev.target.checked)}
          />{" "}
          Cross-Variable Transition Rules
        </h5>
      </Row>
      <Row>
        {enableCrossVariableRules && (
          <CrossEpochVariableRules
            epochVariables={epochVariables}
            crossVariableRules={crossEpochVariableRules}
            setCrossVariableRules={setCrossEpochVariableRules}
          />
        )}
      </Row></Container>
      </Row> */}
      <Row className="mt-4">
      <EraEVLevelEditor epochVariables={epochVariables} crossEpochVariableRules={crossEpochVariableRules} intraEpochRules={intraEpochRules} />
      </Row>
      
    </>
  );
}
export default EraDesigner;
