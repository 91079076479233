import React, { useEffect, useRef } from "react";
import { useState } from "react";

import SectionNotificationsHeader from "../SectionNotificationsHeader";
import { Col, Container, Row } from "react-bootstrap";
import RulesBasedEraConstructor from "./EraCreation/EraConstructor";
import EraDesigner from "./EraCreation/EraDesigner";
import ESFEraConstructor from "./EraCreation/ESFEraConstructor";
import { useStudy, useStudyDispatch } from "../../StudyContext";


function EraCreationSection() {
  const study = useStudy();
  const studyDispatch = useStudyDispatch();
  const epochVariables = study.epochVariables;
  
  const [eraTool, setEraTool] = useState("designer");

  const setBaseline = (epVarId, lev) => {
    studyDispatch({section:"Epoch Variables",
      type: "setBaseline",
      evId: epVarId,
      baselineLevel: lev,
    });
  };

  return (
    <div className="mb-3 EEASection">
      <h4>Era Creation</h4>
      How epoch variables combine over time to create{" "}
      an <em>era</em>, an ordered (and usually time-bound) sequence of epochs.
      <SectionNotificationsHeader sectionName="Era Creation" />
      <Row><Col sm={2}></Col>
        Era Creation Method:{" "}
        <Col sm={2}><select onChange={(ev) => setEraTool(ev.target.value)}>
          <option value="designer">Era Designer</option>
          <option value="ESFconstructor" disabled>ESF Era Constructor</option>
          <option value="advancedConstructor" disabled>Advanced Era Constructor</option>
        </select></Col>
        <Col className="mr-auto"></Col>
      </Row>
      {eraTool === "advancedConstructor" && (
        <RulesBasedEraConstructor epochVariables={epochVariables} />
      )}
      {eraTool === "designer" && (
        <EraDesigner epochVariables={epochVariables} />
      )}
      {eraTool === "ESFconstructor" && (
        <ESFEraConstructor epochVariables={epochVariables} />
      )}
    </div>
  );
}
export default EraCreationSection;
