export function navArray(stakeholders) {
  return [
    {
      name: "Stakeholders",
      class:
        stakeholders && stakeholders.length > 1
          ? "fas fa-users"
          : "fas fa-user",
      section: "MATE",
    },
    { name: "Value Criteria", class: "fas fa-gem", section: "MATE" },
    {
      name: "Alternative Variables",
      class: "fas fa-drafting-compass",
      section: "MATE",
    },
    { name: "MATE Value Matrix", class: "fas fa-th-large", section: "MATE" },
    {
      name: "Epoch Variables",
      class: "fas fa-globe-americas",
      section: "Uncertainty",
    },
    {
      name: "Uncertainty Value Matrix",
      class: "fas fa-th-large",
      section: "Uncertainty",
    },
    {
      name: "Era Creation",
      class: "fas fa-film",
      section: "Uncertainty",
    },
  ];
}
